/* slide.css */
@tailwind utilities;

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: fit-content;
  }
}

@keyframes slideUp {
  from {
    height: fit-content;
  }
  to {
    height: 0;
  }
}

@layer utilities {
  .animate-slide-down {
    animation: slideDown 300ms cubic-bezier(0.8, 0, 0.15, 1);
  }

  .animate-slide-up {
    animation: slideUp 300ms cubic-bezier(0.8, 0, 0.15, 1);
  }
}
